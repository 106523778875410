import React, {useState, useEffect} from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import {getClinica, getToken} from '../../services/auth';
import axios from 'axios';
import { makeStyles, Grid, TextField, MenuItem, IconButton, Button, 
CircularProgress, FormControl, OutlinedInput, InputLabel, InputAdornment} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { mask, unMask } from 'remask';
import Notificacao from '../../utils/notificacao';

export default function MeusDados(){
    const [loading, setLoading] = useState(true);
    const [aviso, setAviso] = useState(false);
    const [tipoAviso, setTipoAviso] = useState('');
    const [mensagemAviso, setMensagemAviso] = useState('');
    const [cpf, setCpf] = useState('');
    const [cpfVerif, setCpfVerif] = useState('');
    const [rg, setRg] = useState('');
    const [nome, setNome] = useState('');
    const [dataNasc, setDataNasc] = useState('');
    const [sexo, setSexo] = useState('');
    const [naturalidade, setNaturalidade] = useState('');
    const [nacionalidade, setNacionalidade] = useState('');
    const [estadoCivil, setEstadoCivil] = useState('');
    const [profissao, setProfissao] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [cep2, setCep2] = useState('');
    const [logradouro2, setLogradouro2] = useState('');
    const [numero2, setNumero2] = useState('');
    const [bairro2, setBairro2] = useState('');
    const [complemento2, setComplemento2] = useState('');
    const [cidade2, setCidade2] = useState('');
    const [estado2, setEstado2] = useState('');
    const [login, setLogin] = useState('');

    useEffect(()=> {
        atualizar();
    },[]);

    const atualizar = async () =>{
        await api.get('Usuario/find.php?id='+getToken()+'&pdo='+getClinica())
        .then(res => {
            setCpf(res.data.cpf); setCpfVerif(res.data.cpf); setRg(res.data.rg); setNome(res.data.nome); setDataNasc(res.data.dataNasc);
            setSexo(res.data.sexo); setNaturalidade(res.data.naturalidade); setNacionalidade(res.data.nacionalidade);
            setEstadoCivil(res.data.estadoCivil); setProfissao(res.data.profissao); setTelefone(res.data.telefone);
            setCelular(res.data.celular); setCep(res.data.endCepR); setLogradouro(res.data.endLograR); setNumero(res.data.endNumR);
            setBairro(res.data.endBairroR); setCidade(res.data.endCidadeR); setEstado(res.data.endEstadoR); setComplemento(res.data.endCompleR);
            setCep2(res.data.endCepP); setLogradouro2(res.data.endLograP); setNumero2(res.data.endNumP); setLogin(res.data.login);
            setBairro2(res.data.endBairroP); setCidade2(res.data.endCidadeP); setEstado2(res.data.endEstadoP); setComplemento2(res.data.endCompleP);
            setLoading(false);
        })
    }

    const verificarCpf = async (cpf) =>{
        let ret = false;
        await api.get('Usuario/verificarCpf.php?pdo='+getClinica()+'&cpf='+cpf)
        .then(res => { ret = res.data })
        return ret;
    }

    const validarCampos = () =>{
        if(cpf !=='' && rg !=='' && nome !=='' && dataNasc!=='' && sexo !=='' && profissao !==''){
             return true;
         } else {
             return false;
         }
    }

    const alterarDados = async () =>{
        if(validarCampos()){
            let existeCPF = await verificarCpf(cpf);
            let CPF ='';
            if(cpf !== cpfVerif){
                if(!existeCPF){ CPF = cpf
                } else { abrirAviso('warning','CPF já cadastrado.'); return '' }
            } else { CPF = cpfVerif }

            await api.post('Usuario/update.php',{
                'pdo': getClinica(), 'cpf': cpf, 'rg': rg, 'nome': nome, 'dataNasc': dataNasc,
                'sexo': sexo, 'naturalidade': naturalidade, 'nacionalidade': nacionalidade, 'estadoCivil': estadoCivil, 
                'profissao': profissao, 'telefone': telefone, 'celular': celular, 'convenio':'', 'nCartao':'', 'login': login,
                'endCepR': cep, 'endLograR': logradouro , 'endNumR': numero, 'endBairroR': bairro, 'endCidadeR': cidade,
                'endEstadoR': estado, 'endCompleR': complemento, 'endCepP': cep2, 'endLograP': logradouro2,
                'endNumP': numero2, 'endBairroP': bairro2, 'endCidadeP': cidade2, 'endEstadoP': estado2,
                'endCompleP': complemento2, 'id': getToken()
            }).then(()=> { abrirAviso('success', 'Dados alterados')})
        } else {
            abrirAviso('warning','Preencha todos os campos obrigatórios.');
        }
    }

    const consultarCepR = () => {
        axios.get('https://api.postmon.com.br/v1/cep/'+cep)
        .then(res => {
            setCep(res.data.cep);
            setLogradouro(res.data.logradouro);
            setBairro(res.data.bairro);
            setCidade(res.data.cidade)
            setEstado(res.data.estado)
        })
        .catch(() => abrirAviso('error','CEP não encontrado'));
    }

    const consultarCepP = () => {
        axios.get('https://api.postmon.com.br/v1/cep/'+cep2)
        .then(res => {
            setCep2(res.data.cep);
            setLogradouro2(res.data.logradouro);
            setBairro2(res.data.bairro);
            setCidade2(res.data.cidade)
            setEstado2(res.data.estado)
        })
        .catch(() => abrirAviso('error','CEP não encontrado'));
    }

    const abrirAviso = (tipo, mensagem) =>{
        setTipoAviso(tipo); setMensagemAviso(mensagem); setAviso(true);
    }

    const fecharAviso = () => { setAviso(false)}

    const useStyles = makeStyles({
        titulos: {
          margin: '8px 0px',
        },
      });

    const classes = useStyles();

    return(
        <div>
            <Menu titulo="Meus dados"/>
            {loading && <CircularProgress style={{marginTop: '250px', display:'block', marginLeft:'auto', marginRight:'auto'}}/>}
            {!loading && <div style={{marginTop:'70px'}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField label="CPF *" size='small' fullWidth value={cpf} onChange={e=> setCpf(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Rg *" size='small' fullWidth value={rg} onChange={e=> setRg(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Nome *" size='small' fullWidth value={nome} onChange={e=> setNome(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField label="Nascimento *" size='small' type="date" fullWidth value={dataNasc} onChange={e=> setDataNasc(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField select label="Sexo *" size='small' fullWidth value={sexo} onChange={e=> setSexo(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}>
                            <MenuItem value="">Selecione</MenuItem>
                            <MenuItem value="m">Masc.</MenuItem>
                            <MenuItem value="f">Fem.</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Naturalidade" size='small' fullWidth value={naturalidade} onChange={e=> setNaturalidade(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Nacionalidade" size='small' fullWidth value={nacionalidade} onChange={e=> setNacionalidade(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField select label="Estado civil" size='small' fullWidth value={estadoCivil} onChange={e=> setEstadoCivil(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}>
                            <MenuItem value="">Selecione</MenuItem>
                            <MenuItem value="1">Solteiro(a)</MenuItem>
                            <MenuItem value="2">Casado(a)</MenuItem>
                            <MenuItem value="3">Separado(a)</MenuItem>
                            <MenuItem value="4">Divorciado(a)</MenuItem>
                            <MenuItem value="5">Viúvo(a)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Profissão *" size='small' fullWidth value={profissao} onChange={e=> setProfissao(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Telefone" size='small' fullWidth value={telefone} onChange={e=> setTelefone(mask(unMask(e.target.value),['(99)9999-9999']))} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Celular" size='small' fullWidth value={celular} onChange={e=> setCelular(mask(unMask(e.target.value),['(99)99999-9999']))} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                </Grid>
                <h5 className={classes.titulos}>Endereço residencial</h5>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" size='small' fullWidth>
                            <InputLabel htmlFor="cep">Cep</InputLabel>
                            <OutlinedInput id="cep" type='text' value={cep} onChange={e=> setCep(mask(unMask(e.target.value),['99999999']))}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={()=>consultarCepR()} edge="end">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                                } labelWidth={70}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Logradouro" size='small' fullWidth value={logradouro} onChange={e=> setLogradouro(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Número" size='small' fullWidth value={numero} onChange={e=> setNumero(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Bairro" size='small' fullWidth value={bairro} onChange={e=> setBairro(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Cidade" size='small' fullWidth value={cidade} onChange={e=> setCidade(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Estado" size='small' fullWidth value={estado} onChange={e=> setEstado(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Complemento" size='small' fullWidth value={complemento} onChange={e=> setComplemento(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                </Grid>
                <h5 className={classes.titulos}>Endereço profissional</h5>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" size='small' fullWidth>
                            <InputLabel htmlFor="cep">Cep</InputLabel>
                            <OutlinedInput id="cep" type='text' value={cep2} onChange={e=> setCep2(mask(unMask(e.target.value),['99999999']))}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={()=>consultarCepP()} edge="end">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                                } labelWidth={70}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Logradouro" size='small' fullWidth value={logradouro2} onChange={e=> setLogradouro2(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Número" size='small' fullWidth value={numero2} onChange={e=> setNumero2(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Bairro" size='small' fullWidth value={bairro2} onChange={e=> setBairro2(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Cidade" size='small' fullWidth value={cidade2} onChange={e=> setCidade2(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Estado" size='small' fullWidth value={estado2} onChange={e=> setEstado2(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Complemento" size='small' fullWidth value={complemento2} onChange={e=> setComplemento2(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={alterarDados}>Alterar</Button>
                    </Grid>
                </Grid>
                <Notificacao aviso={aviso} fecharAviso={()=>setAviso(false)} tipoAviso={tipoAviso} mensagemAviso={mensagemAviso}/>
            </div>}
        </div>)
}