import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, DialogContentText,
Button} from '@material-ui/core';

export default function EstornarCancelarContaPagar({modalCancelar, setModalCancelar, statusConta, idConta, desativarAtivarConta, desabilitar, fullScreen}){

    return(
        <>
            {/* confirmação de cancelamento/estorno de conta */}
            <Dialog fullScreen={fullScreen} open={modalCancelar} onClose={()=> setModalCancelar(false)} aria-labelledby="responsive-dialog-title">
                {statusConta ==='1' && <DialogTitle id="responsive-dialog-title">{"Estornar conta"}</DialogTitle>}
                {statusConta ==='0' && <DialogTitle id="responsive-dialog-title">{"Desativar conta"}</DialogTitle>}
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {statusConta ==='1' ? `Tem certeza que deseja estornar conta Nº ${idConta} ?`: `Tem certeza que deseja desativar conta Nº ${idConta} ?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={desativarAtivarConta} disabled={desabilitar} variant="contained" color="primary">Sim</Button>
                    <Button variant="contained" color="secondary" onClick={()=> setModalCancelar(false)}>Não</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}