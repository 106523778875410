import React, {useState, useEffect} from 'react';
import api from '../../services/api';
import {getClinica } from '../../services/auth';
import {formatData, formatReal} from '../../utils/funcoes';
import Menu from '../Menu';
import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead,
TableRow, Paper, CircularProgress, TextField, Button} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles({
    table: {
      minWidth: 'auto',
    },
    tableCell:{
        fontSize:'12px',
        padding: '4px 4px'
    },
    saldo: {
        margin: '10px 0px',
    }
});

export default function Movimentacao(){
    const [loading, setLoading] = useState(true);
    const [movimentacao, setMovimentacao] = useState([]);
    const [receber, setReceber] = useState(0);
    const [pagar, setPagar] = useState(0);
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');

    useEffect(() =>{
        atualizar();
    },[])

    const atualizar = async () =>{
        let date = new Date();
        let primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
        let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let dateI = date.getFullYear()+'-'+("00"+(date.getMonth()+1)).slice(-2)+'-'+("00" + primeiroDia.getDate()).slice(-2);
        let dateF = date.getFullYear()+'-'+("00"+(date.getMonth()+1)).slice(-2)+'-'+("00" + ultimoDia.getDate()).slice(-2);
        
        listarContas(` AND status=1 AND pagoDia BETWEEN '${dateI}' AND '${dateF}'`);
        
        setDataInicio(dateI);
        setDataFim(dateF);
    }

    const listarContas = async (consulta) => {
        const mov=[];
        await api.post('Pagar/search.php',{ 'pdo':getClinica(), 'consulta': consulta })
        .then(res => {
            let valorPagar=0;
            for(let i=0; i< res.data.length;i++){
                mov.push({'data':res.data[i].pagoDia, 'tipo':'SAIDA' , 'nome':res.data[i].descricao, 'valor':res.data[i].valor});
                valorPagar += parseFloat(res.data[i].valor);
            }
            setPagar(valorPagar);
        }).catch(error =>{console.log(error)})
        await api.post('Receber/search.php',{ 'pdo':getClinica(), 'consulta': consulta})
        .then(res => { 
            let valorReceber=0;
            for(let i=0; i< res.data.length;i++){
                mov.push({'data':res.data[i].pagoDia, 'tipo':'ENTRADA', 'nome':res.data[i].nomeCli, 'valor':res.data[i].valor});
                valorReceber += parseFloat(res.data[i].valor);
            }
            setReceber(valorReceber);
            mov.sort((a,b)=>{ return Date.parse(a.data) - Date.parse(b.data); });
            setMovimentacao(mov);
            setLoading(false);
        }).catch(error =>{console.log(error)})
    }

    const tipoContaIcone = (tipo) => {
        return tipo === 'SAIDA' ? <ArrowUpwardIcon color="error"/> : <ArrowDownwardIcon color="primary"/>
    }

    const filtrar = async () =>{
        if(dataInicio !=='' && dataFim !==''){
            setLoading(true);
            setMovimentacao([]);
            listarContas(` AND status=1 AND pagoDia BETWEEN '${dataInicio}' AND '${dataFim}'`);
            setLoading(false);
        } else {
            setLoading(true);
            await atualizar();
            setLoading(false);
        }
    }

    const classes = useStyles();

    return(
        <div>
            <Menu titulo="Movimentação"/>
            {loading && <CircularProgress style={{marginTop: '250px', display:'block', marginLeft:'auto', marginRight:'auto'}}/>}
            {!loading && <div>
                <Grid container spacing={1} style={{marginTop:'70px'}}>
                    <Grid item xs={6}>
                        <TextField label="Data inicio" size='small' fullWidth value={dataInicio} type="date" onChange={e=> setDataInicio(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Data fim" size='small' fullWidth value={dataFim} type="date" onChange={e=> setDataFim(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" color="primary" onClick={filtrar} size='small'><SearchIcon/></Button>
                    </Grid>
                </Grid>
                <h5 className={classes.saldo}>Saldo: {formatReal(receber- pagar)}</h5>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="movimentacao">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>Data</TableCell>
                                <TableCell className={classes.tableCell}>Tipo</TableCell>
                                <TableCell className={classes.tableCell}>Lançamento</TableCell>
                                <TableCell className={classes.tableCell}>Valor</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {movimentacao.map((lancamento, index) => 
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCell} component="th" scope="row">{formatData(lancamento.data)}</TableCell>
                                    <TableCell className={classes.tableCell}>{tipoContaIcone(lancamento.tipo)}</TableCell>
                                    <TableCell className={classes.tableCell}>{lancamento.nome}</TableCell>
                                    <TableCell className={classes.tableCell}>{formatReal(lancamento.valor)}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>}
        </div>
    )

}