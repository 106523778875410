import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Notificacao({aviso, fecharAviso, tipoAviso, mensagemAviso }){

    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right'}} open={aviso} autoHideDuration={4000} onClose={fecharAviso}>
        <Alert onClose={fecharAviso} severity={tipoAviso}>
            {mensagemAviso}
        </Alert>
        </Snackbar>
    )
}