import React, { useState } from 'react';
import clsx from 'clsx';
import {makeStyles, List, Divider, ListItem, ListItemIcon, 
ListItemText, AppBar, Toolbar, Typography, IconButton, Drawer} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import EventIcon from '@material-ui/icons/Event';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BuildIcon from '@material-ui/icons/Build';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { usePermissoes } from '../../hooks/permissoes';
import PeopleIcon from '@material-ui/icons/People';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { logout, logout_clinica } from '../../services/auth';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function SwipeableTemporaryDrawer(props) {
  const { permissoes } = usePermissoes();
  const classes = useStyles();
  const [state, setState] = React.useState({left: false});

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const sair = (e) => {
    logout();logout_clinica();
    window.location.pathname ='/';
  }

  const opcoesMenu = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <List>
          {permissoes.agenda !== '0' && <ListItem button onClick={()=>window.location.pathname='/app'}>
            <ListItemIcon><EventIcon /></ListItemIcon>
            <ListItemText primary="Agenda" />
          </ListItem>}
          {permissoes.paciente !=='0' && <ListItem button onClick={()=>window.location.pathname='/pacientes'}>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary="Pacientes" />
          </ListItem>}
          {permissoes.financeiro !=='0' && <>
            <ListItem button className={classes.nested} onClick={()=> window.location.pathname='/contaspagar'}>
                <ListItemIcon><ArrowUpwardIcon /></ListItemIcon>
                <ListItemText primary="Contas a pagar" />
            </ListItem>
            <ListItem button className={classes.nested} onClick={()=> window.location.pathname='/contasreceber'}>
                <ListItemIcon><ArrowDownwardIcon /></ListItemIcon>
                <ListItemText primary="Contas a receber" />
            </ListItem>
            <ListItem button onClick={()=>window.location.pathname='/movimentacao'}>
              <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
              <ListItemText primary="Movimentação" />
            </ListItem>
          </>}
      </List>
      <Divider />
      <List>
          <ListItem button onClick={()=>window.location.pathname='/configuracoes'}>
            <ListItemIcon><BuildIcon/></ListItemIcon>
            <ListItemText primary="Configurações"/>
          </ListItem>
          <ListItem button onClick={()=>window.location.pathname='/meusdados'}>
            <ListItemIcon><AccountCircle/></ListItemIcon>
            <ListItemText primary="Meus Dados" />
          </ListItem>
          <ListItem button onClick={sair}>
            <ListItemIcon><ExitToAppIcon/></ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer('left', true)}>
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>{props.titulo}</Typography>
        </Toolbar>
        <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
          {opcoesMenu('left')}
        </Drawer>
      </AppBar>
  </div>
  );
}