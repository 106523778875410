export const formatData = (e) =>{
    if(e !== null){
        let data = e.split('-');
        return data[2]+'/'+data[1]+'/'+data[0];
    } else {
        return ''
    }
}

export const getData = (date, valor) => {
    let data = date.split(' ');
    if(valor ==='data'){ return formatData(data[0]) }
     return data[1] 
}

export const formatReal =( valor ) =>{
    const v = parseFloat(valor);
    return v.toLocaleString("pt-BR", { style: "currency" , currency:"BRL"});
}

export const formaPagamento = (e) =>{
    if(e === 'dinh'){ return 'DINHEIRO' }
    if(e === 'cheq'){ return 'CHEQUE' }
    if(e === 'cartC'){ return 'CARTÃO DE CRÉDITO' }
    if(e === 'cartD'){ return 'CARTÃO DE DÉBITO'  } 
    if(e === 'bole'){ return 'BOLETO'}
    if(e === 'conv'){ return 'CONVÊNIO'}
    if(e === 'transf'){ return 'TRANSFERÊNCIA'}
     else { return '' }
}

export const pegarDataCidade = (empresa) =>{
    let data = new Date();
    let dia = data.getDate();
    let mes = data.getMonth();
    let ano = data.getFullYear();
    let meses = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"]
    return empresa.cidade +'/'+ empresa.estado +', '+ dia +' de '+ meses[mes]+' de '+ ano; 
}