export const TOKEN_KEY = "sigeodonto";
export const TOKEN_CLIN = "clinica";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null && localStorage.getItem(TOKEN_CLIN);
export const getToken = () => {
  let token = localStorage.getItem(TOKEN_KEY);
  let decode = token.substr(19, token.length);
  return atob(decode);
}
export const login = token => {
  let encode = btoa(token);
  localStorage.setItem(TOKEN_KEY, makeid()+encode);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
export const makeid = () => {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 19; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;   
};
export const login_clinica = token_id => {
  let encode = btoa(token_id);
  localStorage.setItem(TOKEN_CLIN, makeid()+encode);
};

export const logout_clinica = () => {
  localStorage.removeItem(TOKEN_CLIN);
}

export const getClinica = () => {
  let token = localStorage.getItem(TOKEN_CLIN);
  let decode = token.substr(19, token.length);
  return atob(decode);
} 

