import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, DialogContentText,
Button} from '@material-ui/core';

export default function EstornarCancelarContaReceber({
    statusConta, fullScreen, modalCancelar, setModalCancelar,
    idConta, desabilitar, desativarAtivarConta
}){

    return(
        <>
            <Dialog fullScreen={fullScreen} open={modalCancelar} onClose={()=> setModalCancelar(false)} aria-labelledby="responsive-dialog-title">
                {statusConta ==='1' && <DialogTitle id="responsive-dialog-title">{"Estornar conta"}</DialogTitle>}
                {statusConta ==='0' && <DialogTitle id="responsive-dialog-title">{"Desativar conta"}</DialogTitle>}
                <DialogContent>
                    {statusConta ==='1' && <DialogContentText id="alert-dialog-slide-description">
                        Tem certeza que deseja estornar conta Nº {idConta} ?
                    </DialogContentText>}
                    {statusConta ==='0' && <DialogContentText id="alert-dialog-slide-description">
                        Tem certeza que deseja desativar conta Nº {idConta} ?
                    </DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={desativarAtivarConta} disabled={desabilitar} variant="contained" color="primary">Sim</Button>
                    <Button variant="contained" color="secondary" onClick={()=> setModalCancelar(false)}>Não</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}