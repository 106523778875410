import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, DialogContentText,
TextField, Button, MenuItem} from '@material-ui/core';

export default function PagarContaReceber({
    modalReceber, setModalReceber, idConta, pagoDia, setPagoDia,
    formaPag, setFormaPag, desabilitar, pagarConta, fullScreen
}){


    return(
        <>
            <Dialog fullScreen={fullScreen} open={modalReceber} onClose={()=> setModalReceber(false)} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{"Estornar conta"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Tem certeza que deseja pagar conta Nº {idConta} ?
                    </DialogContentText>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField fullWidth type="date" label="Data do pagamento" value={pagoDia} onChange={e=> setPagoDia(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth select label="Forma de Pagamento" value={formaPag} onChange={e=> setFormaPag(e.target.value)} variant="outlined">
                                <MenuItem value="" selected>Selecione...</MenuItem>
                                <MenuItem value="dinh">DINHEIRO</MenuItem>
                                <MenuItem value="cheq">CHEQUE</MenuItem>
                                <MenuItem value="cartC">CARTÃO DE CRÉDITO</MenuItem>
                                <MenuItem value="cartD">CARTÃO DE DÉBITO</MenuItem>
                                <MenuItem value="bole">BOLETO</MenuItem>
                                <MenuItem value="conv">CONVÊNIO</MenuItem>
                                <MenuItem value="tranf">TRANSFERÊNCIA</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={pagarConta} disabled={desabilitar} variant="contained" color="primary">Sim</Button>
                    <Button variant="contained" disabled={desabilitar} color="secondary" onClick={()=> setModalReceber(false)}>Não</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}