import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { getClinica, getToken} from '../../../services/auth';
import { usePermissoes } from '../../../hooks/permissoes';
import { useConfig } from '../../../hooks/config';
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions,
TextField, Button, MenuItem, useMediaQuery, makeStyles, useTheme} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PagarContaReceber from './pagarConta';
import EstornarCancelarContaReceber from './estornarCancelar';

const useStyles = makeStyles((theme) => ({
    buttonPagar:{
        backgroundColor: '#4caf50',  
        '&:hover': {
            backgroundColor: '#3b8e3e',
        },
        color: '#FFFF',
     },
}));

export default function EditarCadastrarContaReceber({conta, clientes, modalCadastrarEditar, 
    setModalCadastrarEditar, abrirAviso, contas, setContas }){
    const { permissoes} = usePermissoes();
    const { configuracoes } = useConfig();
    const [idConta, setIdConta] = useState(null);
    const [idCliente, setIdCliente] = useState(null);
    const [desabilitar, setDesabilitar] = useState(false);
    const [emissao, setEmissao] = useState(null);
    const [vencimento, setVencimento] = useState(null);
    const [formaPag, setFormaPag] = useState(null);
    const [statusConta, setStatusConta] = useState('');
    const [valor, setValor] = useState(null);
    const [pagoDia, setPagoDia] = useState(null);
    const [obs, setObs] = useState(null);
    const [modalCancelar, setModalCancelar] = useState(false);
    const [modalReceber, setModalReceber] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const atualizar = () => {
        if(conta){
            setIdConta(conta.id);
            setEmissao(conta.emissao);
            setVencimento(conta.vencimento);
            setIdCliente(conta.idCliente);
            setValor(conta.valor);
            setObs(conta.observacao);
            setPagoDia(conta.pagoDia);
            setStatusConta(conta.status)
        } 
    }

    const validacao = () =>{
        if(idCliente !=='' && valor !=='' && emissao !=='' && vencimento!==''){
            return true
        } return false
    }
    
    const cadastrarConta = async () =>{
        setDesabilitar(true);
        if(configuracoes.bloqueio !== '1'){
            if(permissoes.finCad !=='0' && permissoes !== false){
                if(validacao()){
                    let hoje = new Date();
                    await api.post('Receber/save.php',{
                        'pdo': getClinica(),
                        'idCliente': idCliente,
                        'valor': valor,
                        'formaPag': formaPag,
                        'emissao': emissao,
                        'vencimento': vencimento,
                        'status': 0,
                        'idTratamento': 0,
                        'nDoc': '',
                        'observacao': obs,
                        'dataCadastro': hoje.getFullYear()+'-'+(hoje.getMonth()+1)+'-'+hoje.getDate(),
                        'userCadastro': getToken()
                    }).then((res)=>{
                        setContas([...contas,res.data])
                    }).catch(error =>{console.log(error)})
                    abrirAviso('success','Conta cadastrada'); setModalCadastrarEditar(false);
                } else { abrirAviso('warning','Preencha os campos obrigatórios.'); }
            } else { abrirAviso('error','Você não tem permissão para realizar essa ação.'); }
        } else{ abrirAviso('error','Sistema disponível apenas para consulta. Entre em contato com o suporte.'); }
        setDesabilitar(false);
    }
    
    const alterarConta = async () => {
        setDesabilitar(true);
        if(configuracoes.bloqueio !== '1'){
            if(permissoes.finAlt !=='0' && permissoes !== false){
                if(validacao()){
                    let hoje = new Date();
                    await api.post('Receber/update.php',{
                        'pdo': getClinica(),
                        'idCliente': idCliente,
                        'valor': valor,
                        'formaPag': formaPag,
                        'emissao': emissao,
                        'vencimento':vencimento,
                        'observacao': obs,
                        'dataUltAlteracao': hoje.getFullYear()+'-'+(hoje.getMonth()+1)+'-'+hoje.getDate(),
                        'userUltAlteracao': getToken(),
                        'id': idConta
                    }).then((res)=>{ 
                        setContas(state => state.map(e => { if(e.id !== res.data.id){ return e } e = res.data; return e }));
                        abrirAviso('info','Conta alterada'); setModalCadastrarEditar(false); 
                    }).catch(error =>{console.log(error)})
                } else { abrirAviso('warning','Preencha os campos obrigatórios.'); }
            } else { abrirAviso('error','Você não tem permissão para realizar essa ação.'); }
        } else{ abrirAviso('error','Sistema disponível apenas para consulta. Entre em contato com o suporte.'); }
        setDesabilitar(false)
    }

    const salvar = () => {
        if(idConta !==''){ alterarConta()
        } else{ cadastrarConta()}
    }

    const pagarConta = async (e) => {
        setDesabilitar(true);
        e.preventDefault();
        if(configuracoes.bloqueio !== '1'){
            if(permissoes.finAlt !=='0' && permissoes !== false){
                if(pagoDia !==''){
                    let hoje = new Date();
                    await api.post('Receber/pay.php', {
                        'pdo': getClinica(),
                        'status' : 1,
                        'dataPagamento': pagoDia,
                        'formaPagamento': formaPag,
                        'idUserBaixa' : getToken(),
                        'dataUltAlteracao': hoje.getFullYear()+'-'+(hoje.getMonth()+1)+'-'+hoje.getDate(),
                        'userUltAlteracao': getToken(),
                        'id': idConta
                    }).then((res) =>{ abrirAviso('success','Conta paga'); 
                        setContas(state => state.map(e => { if(e.id !== res.data.id){ return e } e = res.data; return e }));
                        setModalReceber(false); setModalCadastrarEditar(false);
                    }).catch(error =>{console.log(error)})
                } else { abrirAviso('warning','Preencha os campos obrigatórios.');  }
            } else { abrirAviso('error','Você não tem permissão para realizar essa ação.'); }
        } else{ abrirAviso('error','Sistema disponível apenas para consulta. Entre em contato com o suporte.'); }
        setDesabilitar(false);
    }

    const desativarAtivarConta = async () => {
        setDesabilitar(true);
        if(configuracoes.bloqueio !== '1'){
            if(permissoes.finDes !=='0' && permissoes.finAlt !=='0' && permissoes !== false){
                let hoje = new Date();
                let acao =0;
                if(statusConta === '0'){ acao = -1 }
                await api.post('Receber/chargeBack.php',{
                    'pdo': getClinica(),
                    'status': acao,
                    'dataUltAlteracao': hoje.getFullYear()+'-'+(hoje.getMonth()+1)+'-'+hoje.getDate(),
                    'userUltAlteracao': getToken(),
                    'id': idConta
                }).then((res)=>{abrirAviso('info','Conta alterada.'); 
                    setContas(state => state.map(e => { if(e.id !== res.data.id){ return e } e = res.data; return e }));
                    setModalCancelar(false); setModalCadastrarEditar(false);
                }).catch(error =>{console.log(error)})
            } else { abrirAviso('error','Você não tem permissão para realizar essa ação.'); }
        } else{ abrirAviso('error','Sistema disponível apenas para consulta. Entre em contato com o suporte.'); }
        setDesabilitar(false);
    }

    useEffect(()=>{
        atualizar();
    },[modalCadastrarEditar])

    return(
        <>
            <Dialog fullScreen={fullScreen} open={modalCadastrarEditar} onClose={()=> setModalCadastrarEditar(false)} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{idConta ? `Conta Nº ${idConta}`:"Cadastrar Conta"}</DialogTitle>
                <DialogContent>
                    <form className={classes.form} noValidate autoComplete="off">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField fullWidth select disabled={desabilitar} label="Cliente" value={idCliente} onChange={e=> setIdCliente(e.target.value)} variant="outlined">
                                    <MenuItem value="" selected>Selecione...</MenuItem>
                                    {clientes.map((cli) =><MenuItem key={cli.id} value={cli.id}>{cli.nome}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth disabled={desabilitar} type="date" label="Emissão" value={emissao} onChange={e=> setEmissao(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth disabled={desabilitar} type="date" label="Vencimento" value={vencimento} onChange={e=> setVencimento(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth disabled={desabilitar} type="number" label="Valor" value={valor} onChange={e=> setValor(e.target.value)} variant="outlined"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth select disabled={desabilitar} label="Forma de Pagamento" value={formaPag} onChange={e=> setFormaPag(e.target.value)} variant="outlined">
                                    <MenuItem value="" selected>Selecione...</MenuItem>
                                    <MenuItem value="dinh">DINHEIRO</MenuItem>
                                    <MenuItem value="cheq">CHEQUE</MenuItem>
                                    <MenuItem value="cartC">CARTÃO DE CRÉDITO</MenuItem>
                                    <MenuItem value="cartD">CARTÃO DE DÉBITO</MenuItem>
                                    <MenuItem value="bole">BOLETO</MenuItem>
                                    <MenuItem value="conv">CONVÊNIO</MenuItem>
                                    <MenuItem value="tranf">TRANSFERÊNCIA</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth disabled={desabilitar} label="Observação" value={obs} onChange={e=> setObs(e.target.value)} variant="outlined"/>
                            </Grid>
                            {idConta !== '' && statusConta ==='1' && <Grid item xs={12}>
                                <TextField fullWidth disabled={desabilitar} type="date" label="Data Pagamento" value={pagoDia} variant="outlined" InputLabelProps={{shrink: true,}}/>
                            </Grid>}
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    {statusConta==='0' && idConta!=='' && <Button onClick={setModalCancelar} className={classes.buttonCancel} variant="contained" title="Cancelar conta" color="secondary"><DeleteIcon/></Button>}
                    {statusConta==='1' && idConta!=='' &&  <Button onClick={setModalCancelar} variant="contained" title="Estornar conta" color="default">Estornar</Button>}
                    {statusConta==='0' &&  idConta!=='' && <Button onClick={()=> setModalReceber(true)} className={classes.buttonPagar} variant="contained">Pagar</Button>}
                    {statusConta !=='-1' && statusConta !=='1' && <Button onClick={salvar} variant="contained" color="primary" disabled={desabilitar}>Salvar</Button>}
                    <Button variant="contained" color="default" onClick={()=> setModalCadastrarEditar(false)}>Fechar</Button>
                </DialogActions>
            </Dialog>

            {modalReceber && <PagarContaReceber modalReceber={modalReceber} setModalReceber={setModalReceber} idConta={idConta} pagoDia={pagoDia} setPagoDia={setPagoDia}
            formaPag={formaPag} setFormaPag={setFormaPag} desabilitar={desabilitar} pagarConta={pagarConta} fullScreen={fullScreen}/>}

            {modalCancelar && <EstornarCancelarContaReceber statusConta={statusConta} fullScreen={fullScreen} modalCancelar={modalCancelar} setModalCancelar={setModalCancelar}
                idConta={idConta} desabilitar={desabilitar} desativarAtivarConta={desativarAtivarConta} />} 
        </>
    )
}