import React, { useState, useEffect } from 'react';
import Menu from '../../Menu';
import api from '../../../services/api';
import {getClinica, getToken } from '../../../services/auth';
import { formatData, formatReal } from '../../../utils/funcoes';
import { Grid, Accordion, AccordionSummary, AccordionDetails, 
Typography, Button, TextField, MenuItem, makeStyles, Paper, CircularProgress, 
Avatar, Tooltip, Zoom, Fab, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import EditarCadastrarContaReceber from './editarCadastrar';
import AddIcon from '@material-ui/icons/Add';
import Notificacao from '../../../utils/notificacao';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(8),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    buttonFiltro:{
        margin: theme.spacing(1),
    },
    rootGrid: {
        flexGrow: 1,
        overflow: 'hidden',
    },
    faturaHead:{
        fontSize: '13px'
    },
    faturaBody:{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '11px'
    },
    paper: {
        maxWidth: 600,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#EEEDED',
        },
        cursor: 'pointer'
      },
    amarelo:{
        backgroundColor:'#FFC107',
    },
    verde:{
        backgroundColor: '#4caf50',
    },
    vermelho:{
       backgroundColor: '#FF0000',
    },
    contaVencida:{
        backgroundColor:'#f8beb7',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    totalizadores:{
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default function ContasReceber(){
    const [loading, setLoading] = useState(true);
    const [contas, setContas] = useState([]);
    const [totalReceber, setTotalReceber] = useState(0);
    const [totalRecebido, setTotalRecebido] = useState(0);
    const [buscar, setBuscar] = useState('');
    const [filtro, setFiltro] = useState('');
    const [status, setStatus] = useState('status=0');
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [clientes, setClientes] = useState([]);
    const [conta, setConta] = useState(null);
    const [modalCadastrarEditar, setModalCadastrarEditar] = useState(false);
    const [aviso, setAviso] = useState(false);
    const [tipoAviso, setTipoAviso] = useState('');
    const [mensagemAviso, setMensagemAviso] = useState('');
    const classes = useStyles();

    const ehContaVencida = (situacao, e) => {
        let vencimento = e.split("-");
        let data = new Date(vencimento[0], vencimento[1]-1, vencimento[2]);
        let hoje = new Date();
        if(data < hoje && situacao ==='0'){
            return classes.contaVencida;
        } 
    }

    const abrirAviso = (tipo, mensagem) =>{
        setTipoAviso(tipo); setMensagemAviso(mensagem); setAviso(true);
    }

    const filtrar = async () => {
        setLoading(true);
        let filtroBusca ='';
        if(status !=='3'){
            filtroBusca += ` AND ${status}`;
        }
        
        if(dataInicio && dataFim && filtro){
            filtroBusca += ` AND ${filtro} BETWEEN '${dataInicio}' AND '${dataFim}'`;
        } else { filtroBusca +=  ' ORDER BY vencimento ASC'; }  
        await listarContas(filtroBusca); 
        setLoading(false);       
        
    }

    const listarContas = async (consulta) =>{
        setContas([]);
        await api.post('Receber/search.php',{ 'pdo':getClinica(), 'consulta':consulta }).then(res =>{
            if(res.data.length >0 ){setContas(res.data); } 
        }).catch(error => console.log(error))
    }

    const situacaoConta = (valor) => {
        if(valor ==='0'){ return <Tooltip title='CONTA PENDENTE' TransitionComponent={Zoom}>
            <Avatar className={classes.amarelo}>PE</Avatar>
        </Tooltip>}
        if(valor ==='1'){ return <Tooltip title='CONTA PAGA' TransitionComponent={Zoom}>
            <Avatar className={classes.verde}>PG</Avatar>
        </Tooltip>}
        else { return <Tooltip title='CONTA CANCELADA' TransitionComponent={Zoom}>
            <Avatar className={classes.vermelho}>CA</Avatar>
        </Tooltip>}
    }

    const atualizar = async () => {
        await listarContas('AND status=0 ORDER BY vencimento ASC');
        await api.get('Usuario/list_select.php?pdo='+getClinica()).then(res => { setClientes(res.data) })
        .catch(error =>{console.log(error)})
        setLoading(false); 
    }

    const totalAReceber = (contas) =>{
        let totalRecebido = contas.filter(c => c.status === '1').reduce((sum, li) => sum + (Number(li.valor)),0);
        let totalReceber = contas.filter(c => c.status === '0').reduce((sum, li) => sum + (Number(li.valor)),0);
        setTotalReceber(totalReceber); setTotalRecebido(totalRecebido)
     }

    const abrirConta = (conta) =>{
        setConta(conta);
        setModalCadastrarEditar(true);
    }

    const abrirCadastro = () => {
        setConta(null);
        setModalCadastrarEditar(true);
    }

    useEffect(()=>{
        atualizar();
    },[])

    useEffect(()=>{
        totalAReceber([...contas]);
    },[contas])

    const filtradoPorNome = contas.filter((conta) => { return conta.nomeCli.toLowerCase().indexOf(buscar.toLowerCase()) !== -1; });

    return(
        <>
            <Menu titulo="Contas a Receber"/>
            <div className={classes.root}>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}>Pesquisa Avançada</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField fullWidth value={buscar} onChange={e=> setBuscar(e.target.value)} size='small' label="Pesquisar" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth select size='small' label="Status" value={status} onChange={e=> setStatus(e.target.value)} variant="outlined" >
                                    <MenuItem value="3">Todas</MenuItem>
                                    <MenuItem value="status=0">Aberto</MenuItem>
                                    <MenuItem value="status=1">Pago</MenuItem>
                                    <MenuItem value="status=-1">Cancelado</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth select size='small' label="Filtro por" value={filtro} onChange={e=> setFiltro(e.target.value)} variant="outlined" >
                                    <MenuItem value="">Selecione...</MenuItem>
                                    <MenuItem value="emissao">Emissão</MenuItem>
                                    <MenuItem value="vencimento">Vencimento</MenuItem>
                                    <MenuItem value="pagoDia">Pagamento</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth type="date" size="small" label="Periodo Inicial" value={dataInicio} onChange={e=> setDataInicio(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth type="date" size="small" label="Periodo Final" value={dataFim} onChange={e=> setDataFim(e.target.value)} variant="outlined" InputLabelProps={{shrink: true,}}/>
                            </Grid>
                            <Button fullWidth onClick={filtrar} variant="contained" color="secondary"><SearchIcon/></Button> 
                        </Grid>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
            {loading && <CircularProgress style={{marginTop: '200px', display:'block', marginLeft:'auto', marginRight:'auto'}}/>}
            {!loading && <div className={classes.rootGrid}>
                <Box display="flex" p={0} bgcolor="background.paper">
                    <Box p={1} width="100%" className={classes.totalizadores}>
                        <b><ArrowDownwardIcon color="error"/> {formatReal(totalReceber)}</b>
                    </Box>
                    <Box p={1} width="100%" className={classes.totalizadores}>
                        <b><CheckCircleIcon color="primary"/> {formatReal(totalRecebido)}</b>
                    </Box>
                </Box>
                {filtradoPorNome.map(conta => <Paper className={classes.paper} key={conta.id} onClick={()=>abrirConta(conta)}>
                    <Grid container wrap="nowrap" spacing={2} className={ehContaVencida(conta.status,conta.vencimento)}>
                        <Grid item>
                            {situacaoConta(conta.status)}
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.faturaHead}><b>Cliente:</b> {conta.nomeCli}</Typography>
                            <Typography className={classes.faturaBody}>
                                <div>
                                    <b>Vencimento:</b> {formatData(conta.vencimento)}
                                </div> 
                                <div>
                                    <b>Valor:</b> {formatReal(conta.valor)}
                                </div> 
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>)}
            </div>}

            {modalCadastrarEditar && <EditarCadastrarContaReceber
                conta={conta} clientes={clientes} modalCadastrarEditar={modalCadastrarEditar} 
                setModalCadastrarEditar={setModalCadastrarEditar} abrirAviso={abrirAviso} contas={contas} setContas={setContas}
            />}

            <Notificacao aviso={aviso} fecharAviso={()=>setAviso(false)} tipoAviso={tipoAviso} mensagemAviso={mensagemAviso}/>
            <Fab className={classes.fab} color='primary' onClick={abrirCadastro}>
                <AddIcon/>
            </Fab>
        </>
    )
}