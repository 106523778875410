import React, {useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import ptlocales from '@fullcalendar/core/locales/pt-br';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { usePermissoes } from '../../hooks/permissoes';
import { useConfig } from '../../hooks/config';
import Menu from '../Menu';
import api from '../../services/api';
import {getClinica, getToken } from '../../services/auth';
import {makeStyles, useTheme, CircularProgress, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import './styles.css';
import InserirEvento from './inserirEvento';
import VisualizarEvento from './visualizarEvento';
import Notificacao from '../../utils/notificacao';

export default function Agenda() { 
    const [loading, setLoading] = useState(true);
    const [desativar, setDesativar] = useState(false);
    const {permissoes} = usePermissoes();
    const {configuracoes} = useConfig();
    const [eventos, setEventos] = useState([]);
    const [clientes, setClientes]= useState([]);
    const [dentistas, setDentistas]= useState([]);
    const [feriados, setFeriados]= useState([]);
    const [abrirModal, setAbrirModal] = useState(false);
    const [ModalCadastro, setAbrirModalCadastro] = useState(false);
    const [idEvento, setIdEvento] = useState('');   
    const [title, setTitle] = useState(null); 
    const [idTratamento, setIdTratamento] = useState(null);
    const [idCliente, setIdCliente]= useState(null);
    const [idDentista, setIdDentista]= useState(null);
    const [dataInicio, setDataIncio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [horaInicio, setHoraInicio]= useState('');
    const [horaFim, setHoraFim]= useState('');
    const [obsEvento, setObsEvento]= useState('');
    const [presenca, setPresenca]= useState('#6c757d');
    const [descFeriado, setDescricaoFeriado] = useState('');
    const [aviso, setAviso] = useState(false);
    const [tipoAviso, setTipoAviso] = useState('');
    const [mensagemAviso, setMensagemAviso] = useState('');

    useEffect(() =>{
        atualizar();
    },[])

    const useStyles = makeStyles((theme) => ({
        fab: {
            position: 'fixed',
            bottom: theme.spacing(1.5),
            right: theme.spacing(1.5),
            zIndex:10
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        select: {
            marginBottom: theme.spacing(1),
        },
        textField: {
            marginTop: theme.spacing(1),

          },
    }));

    const atualizar = async () => {
        await api.get('Agenda/list_Dentista.php?id='+getToken()+'&pdo='+getClinica()).then(res => { setEventos(res.data) })
        .catch(error => {console.log(error)})
        await api.get('Feriado/list.php?pdo='+getClinica()).then(res => {
            const feriados = []; setFeriados(res.data);
            let ano = new Date();
           for(let i=0; i< res.data.length; i++){
               feriados.push({start: `${ano.getFullYear()}-${res.data[i].mes}-${res.data[i].dia}`, 
               end:`${ano.getFullYear()}-${res.data[i].mes}-${res.data[i].dia}`,
               rendering: 'background',
               color: '#FF0000'})
           } setEventos(eventos  => eventos.concat(feriados)) })
        await api.get('Usuario/list_select.php?pdo='+getClinica()).then(res => { setClientes(res.data) })
        .catch(error => {console.log(error)})
        await api.post('Usuario/list_func_select.php?id=1&pdo='+getClinica()).then(res =>{ setDentistas(res.data) })
        .catch(error => {console.log(error)})
        setLoading(false); 
    }

    const abrirModalCadastro = () => { limparCampos(); setAbrirModalCadastro(true) }

    const eventoClick = (arg) => { 
        let dia = new Date(arg.event.start);
        let diaFuturo = dia.getFullYear()+'-'+("0"+(dia.getMonth()+1)).slice(-2)+'-'+("0"+(dia.getDate())).slice(-2);
        let dados = arg.event.extendedProps;
        setIdEvento(arg.event.id);
        let horaI= new Date(arg.event.start), horaF=new Date(arg.event.end);
        setHoraInicio(("00" + horaI.getHours()).slice(-2)+':'+("00" + horaI.getMinutes()).slice(-2));
        setHoraFim(("00" + horaF.getHours()).slice(-2)+':'+("00" + horaF.getMinutes()).slice(-2));
        setDataIncio(horaI.getFullYear()+'-'+("00"+(horaI.getMonth()+1)).slice(-2)+'-'+horaI.getDate())
        setDataFim(horaF.getFullYear()+'-'+("00"+(horaF.getMonth()+1)).slice(-2)+'-'+horaF.getDate())
        if(arg.event.backgroundColor ===""){ setPresenca(0);
        } else{ setPresenca(arg.event.backgroundColor)}

        dados.idTratamento ? setDesativar(true): setDesativar(false);
        setObsEvento(dados.observacao);
        setTitle(arg.event.title);
        setIdTratamento(dados.idTratamento);
        setIdCliente(dados.idCliente);   
        setIdDentista(dados.idDentis);    
        setAbrirModal(true);
    }

    const nomeCliente = (id) =>{
        for(let i=0; i < clientes.length; i++){
            if(clientes[i].id === id){
                return clientes[i].nome;
            }
        }
    }

    const validarCampos = () =>{
        if(horaInicio !=='' && horaFim !=='' && idCliente !=='' && idDentista !==''){
            return true;
        } return false;
    }

    const limparCampos = () => {
        setIdEvento(''); setIdCliente(null); setIdDentista(null); setDataIncio(''); setDataFim(''); 
        setHoraInicio(''); setHoraFim(''); setObsEvento(''); setPresenca('');
    }

    const abrirAviso = (tipo, mensagem) =>{
        setTipoAviso(tipo); setMensagemAviso(mensagem); setAviso(true);
    }

    const classes = useStyles();

    return(
        <div>
            <Menu titulo="Agenda"/>
            {loading && <CircularProgress style={{marginTop: '250px', display:'block', marginLeft:'auto', marginRight:'auto'}}/>}
            {!loading && <div style={{marginTop: '70px'}}>
                <FullCalendar initialView="listMonth" dayMaxEvents={0}
                plugins={[ listPlugin, interactionPlugin, dayGridPlugin]} headerToolbar={{ left:'title', center:null, right:'prev,next,today,listMonth,dayGridMonth'}} 
                locale={ptlocales} events={eventos} eventClick={eventoClick} />
            </div>}
            <Fab className={classes.fab} color='primary' onClick={abrirModalCadastro}>
                <AddIcon/>
            </Fab>
            {/* vizualizar ou alterar agendamento */}
            {abrirModal && <VisualizarEvento
                configuracoes={configuracoes}
                permissoes={permissoes}
                abrirAviso={abrirAviso}
                nomeCliente={nomeCliente}
                validarCampos={validarCampos}
                abrirModal={abrirModal}
                setAbrirModal={setAbrirModal}
                idCliente={idCliente}
                setIdCliente={setIdCliente}
                desativar={desativar}
                idTratamento={idTratamento}
                title={title}
                idEvento={idEvento}
                setEventos={setEventos}
                idDentista={idDentista}
                setIdDentista={setIdDentista}
                dataInicio={dataInicio}
                dataFim={dataFim}
                horaInicio={horaInicio}
                setHoraInicio={setHoraInicio}
                horaFim={horaFim}
                setHoraFim={setHoraFim}
                obsEvento={obsEvento}
                setObsEvento={setObsEvento}
                presenca={presenca}
                setPresenca={setPresenca}
                clientes={clientes}
                dentistas={dentistas}
            />}
            {/* cadastrar agendamento */}
            {ModalCadastro && <InserirEvento 
                configuracoes={configuracoes}
                permissoes={permissoes}
                eventos={eventos}
                setEventos={setEventos}
                validarCampos={validarCampos}
                idCliente={idCliente}
                setIdCliente={setIdCliente}
                idDentista={idDentista}
                setIdDentista={setIdDentista}
                dataInicio={dataInicio}
                setDataIncio={setDataIncio}
                horaInicio={horaInicio}
                setHoraInicio={setHoraInicio}
                horaFim={horaFim}
                setHoraFim={setHoraFim}
                obsEvento={obsEvento}
                setObsEvento={setObsEvento}
                presenca={presenca}
                ModalCadastro={ModalCadastro}
                setAbrirModalCadastro={setAbrirModalCadastro}
                abrirAviso={abrirAviso}
                nomeCliente={nomeCliente}
                clientes={clientes}
                dentistas={dentistas}
                descFeriado={descFeriado}
                setDescricaoFeriado={setDescricaoFeriado}
                feriados={feriados}
            />}
            <Notificacao aviso={aviso} fecharAviso={()=>setAviso(false)} tipoAviso={tipoAviso} mensagemAviso={mensagemAviso}/>
        </div>
    )
}