import React, {useEffect, useState } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica} from '../../services/auth';
import { makeStyles, List, ListItem, ListItemSecondaryAction,
ListItemText, ListItemAvatar, Avatar, CircularProgress, Fab} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import Notificacao from '../../utils/notificacao';
import EditarCadastrarPaciente from './editarCadastrar';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop:'55px',
      width: '100%',
      maxWidth: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
}));

export default function Pacientes() {
    const [loading, setLoading] = useState(true);
    const [convenios,setConvernios] = useState([]);
    const [aviso, setAviso] = useState(false);
    const [tipoAviso, setTipoAviso] = useState('');
    const [mensagemAviso, setMensagemAviso] = useState('');
    const [pacientes, setPacientes] = useState([]);
    const [paciente, setPaciente] = useState(null);
    const [modalEditarCadastrar, setModalEditarCadastrar] = useState(false);
    const classes = useStyles();

    useEffect(()=>{
        atualizar();
    },[]);

    const abrirAviso = (tipo, mensagem) =>{
        setTipoAviso(tipo); setMensagemAviso(mensagem); setAviso(true);
    }

    const atualizar = async () => {
        listarClientes('AND u.tipo=0 AND u.ativo=0 ORDER BY u.nome ASC');
        await api.get('Convenio/list.php?pdo='+getClinica()).then(res =>{ setConvernios(res.data)})
        setLoading(false);
    }

    const listarClientes = async (consulta) => { 
        setPacientes([]);
        await api.post('Usuario/search.php',{'pdo':getClinica(), 'consulta':consulta}).then(res =>{
        res.data.length >0 ? setPacientes(res.data):setPacientes([]) 
        })
        setLoading(false);
    }

    const abrirDados = (paciente) =>{
        setPaciente(paciente);
        setModalEditarCadastrar(true);
    }

    const cadastrar = () => {
        setPaciente(null);
        setModalEditarCadastrar(true);
    }

    return(<div>
        <Menu titulo="Pacientes"/>
            {loading && <CircularProgress style={{marginTop: '250px', display:'block', marginLeft:'auto', marginRight:'auto'}}/>}
            {!loading && <List dense className={classes.root}>
                {pacientes.map((paciente) => {
                    return (
                    <ListItem key={paciente.id} button onClick={()=>abrirDados(paciente)}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={paciente.nome} />
                        <ListItemSecondaryAction>
                                {/* <Button variant="contained" color="primary" size='small'><OpenInNewIcon/></Button> */}
                        </ListItemSecondaryAction>
                    </ListItem>
                    );
                })}
        </List>}

        {modalEditarCadastrar && <EditarCadastrarPaciente paciente={paciente} convenios={convenios} modalEditarCadastrar={modalEditarCadastrar}
            setModalEditarCadastrar={setModalEditarCadastrar} abrirAviso={abrirAviso} 
            classes={classes} pacientes={pacientes} setPacientes={setPacientes}/>}

        <Fab className={classes.fab} color='primary' onClick={cadastrar}>
            <AddIcon/>
        </Fab>
        <Notificacao aviso={aviso} fecharAviso={()=>setAviso(false)} tipoAviso={tipoAviso} mensagemAviso={mensagemAviso}/>
    </div>)
}